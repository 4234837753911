.search-component {
  grid-column: searchbox;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: center;
  padding-top: 30px;
  transition: .35s cubic-bezier(.49,1,.22,1) .35ms;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;

  &--inline {
    position: relative;
    top: auto;
    left: auto;
    height: auto;
    padding-top: 0;
    opacity: 1;
    visibility: visible;
    z-index: 0;

    .search-component__input {
      border: 1px solid fade_out($body-color,0.5)!important;
    }
  }

  form {
    width: calc(100% - 2rem);
  }

  &__header {
    margin-bottom: 1rem;
    * {
      color: $white;
    }
  }

  &__body {
    display: flex;
  }

  &__input {
    border: 1px solid #fff!important;
    padding-left: 15px;
    padding-right: 45px;
    height: 60px;
    width: 100%;
    outline: 0;

    @include fluid-type($min_width, $max_width, (24px/1.6), 24px, 0.9, 0.9);
  }

  &__button {
    width: 90px;
    height: 60px;
    border: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    outline: 0;
    z-index: 2;
    cursor: pointer;

    i {
      display: inline-block;
      height: 44px;
      text-align: center;
      @include fluid-type($min_width, $max_width, (30px), 40px, 1, 1);
      line-height: 44px!important;
      transition: .35s cubic-bezier(.49,1,.22,1) .35ms;
    }

    &:focus, &:hover {
      outline: none;
      i {
        transform: scale(1.05);
      }
    }
  }
}

body.search-component--open {
  overflow: hidden;
  #page-wrapper {
    &:before {
      visibility: visible;
      opacity: 1;
    }
  }
  .search-component {
    visibility: visible;
    opacity: 1;
  }
}

@include media-breakpoint-up(md) {
  .search-component {
    form {
      width: 60%;
    }
  }
}

@include media-breakpoint-up(lg) {
  .search-component {
    form {
      width: 50%;
    }
  }
}

@include media-breakpoint-up(xl) {
  .search-component {
    form {
      width: 40%;
    }
  }
}

.search-location-component {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;

  &__body {
    background-color: $body-color;
    padding: 1.125rem 1.125rem 10vw 1.125rem;
    overflow: hidden;

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2,.h2 {
        margin-bottom: 0;
      }
    }

    input[type=text] {
      //@include fluid-type($min_width, $max_width, (16px), 20px, 1, 1);
      border: none;
      //border-radius: 22px;
    }
    input[type=submit], button[type=submit] {
      margin-left: 1rem;
      @include fluid-type($min_width, $max_width, (16px), 20px, 1.1, 1.25);
      border-radius: 22px;
    }
  }
}

@include media-breakpoint-up(md) {
  .search-location-component {
    position: absolute;
    top: 0;
    right: 0;

    &__body {
      padding: 1.5rem;
      border-top-left-radius: 2.1875rem;
      border-bottom-left-radius: 2.1875rem;
      min-width: 460px;
    }
  }
}
