.contact {
  background-color: $secondary;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999;

  .contact-select {
    margin: 0.35rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $white;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;

    span.custom-arrow {
      margin-left: 0.35rem;
      display: inline-flex;
      &:before {
        border-left: 2px solid $white;
        border-bottom: 2px solid $white;
        transform: translateY(0) rotate(-45deg);
        transition: 0.3s;
      }
    }

    &.collapsed {
      span {
        &:before {
          transform: translateY(3px) rotate(135deg);
        }
      }
    }
  }

  ul {
    background-color: $primary;
    padding: 0.35rem 0;
    margin: 0;
    list-style-type: none;
    text-align: left;

    li {

      a {
        color: $white;
        padding: 0.35rem 1.5rem;
        display: inline-block;
        width: 100%;
      }

    }

    &.inline {
      display: inline-block;

      li {
        display: inline-block;
        float: left;

        a {
          text-decoration: none;
        }
      }
    }
  }
}