.nav-container {
  background-color: $white;
  width: 100%;
  z-index: 9;
  transition: 0.3s;

  .navbar {
    position: relative;
    margin: 0 auto;
    max-width: 1600px;
    justify-content: space-between;
    display: flex;
    align-items: center;

    .navbar-brand {
      display: inline-block;
      font-size: 0;
      line-height: inherit;
      z-index: 0;
      img {
        max-width: 84px;
      }
    }
    .nav {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
ul.nav-desktop {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
  z-index: 99;
  display: none;
  > li {
    display: inline-block;

    > a {
      position: relative;
      display: inline-block;
      @extend %font-family-bree-serif;
      @include fluid-type($min_width, $max_width, (22.5px/1.25), 22.5px, 1, 1);
      font-weight: 600;
      line-height: 96px;
      text-decoration: none;
      transition: 0.3s;

      &:not(.btn){
        padding: 0 0.8rem;
        color: $body-color;
      }
    }

    &.has-subitems {
      position: relative;
      .custom-arrow {
        margin-left: 0.35rem;
        display: inline-flex;
        &:before {
          transform: translateY(-3px) rotate(-45deg);
          transition: 0.3s;
        }
      }
      > a {
        &:before {
          width: 100%;
        }
      }
    }

    //mega submenu
    > .submenu {
      background-color: $white;
      position: absolute;
      top: 100%;
      left: 1rem;
      visibility: hidden;
      opacity: 0;
      transition: 0.3s;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        &.submenu-items {
          min-width: 200px;
          border-left: 2px solid $secondary;
          @include fluid-type($min_width, $max_width, (20px/1.25), 20px, 1, 1);

          > li {
            position: relative;
            padding: 0.5rem 0;
            margin: 0 1rem;
            white-space: nowrap;

            &:before {
              content: "";
              background-color: $secondary;
              height: 0.75rem;
              width: 0.75rem;
              border-radius: 6px;
              position: absolute;
              top: 0.875rem;
              left: -1.45rem;
              opacity: 0;
              transition: 0.3s;
            }

            a {
              position: relative;
              color: $body-color;
              font-weight: 400;
              text-decoration: none;
              transition: 0.3s;

              &:before {
                content: "";
                height: 1px;
                border-radius: 1px;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                background-color: $body-color;
                transition: 0.3s;
              }
            }

            &.active, &:hover {
              &:before {
                opacity: 1;
              }
              a {
                &:before {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    //open submenu on click
    &.show {
      > a:not(.btn) {
        color: $secondary;
        &:before {
          background-color: $secondary;
          width: 100%;
        }
      }

      .custom-arrow {
        &:before {
          transform: rotate(135deg);
        }
      }

      .submenu {
        visibility: visible;
        opacity: 1;
      }
    }

    //active item
    &.active {
      > a:not(.btn) {
        color: black;
        &:before {
          content: "";
          height: 1px;
          border-radius: 1px;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0%;
          background-color: $body-color;
          transition: 0.3s;
        }
        &:before {
          width: 100%;
        }
      }
      > a {
        color: white;
      }
    }
  }
}

//open submenu on hover
body.is_desktop {
  ul.nav-desktop {
    > li {

      &:hover {
        > a:not(.btn) {
          color: $secondary;
          &:before {
            background-color: $secondary;
            width: 100%;
          }
        }

        .custom-arrow {
          &:before {
            transform: rotate(135deg);
          }
        }

        .submenu {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

#nav-secundary {
  display: none;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0 1rem;

  > li {
    display: inline-block;
    > a {
      padding: 0 2rem;
      display: inline-block;
      @extend %font-family-bree-serif;
      font-weight: 600;
      line-height: 40px;
      text-decoration: none;
    }
  }
}

@include media-breakpoint-up(sm) {
  .nav-container {
    .navbar {
      .navbar-brand {
        img {
          max-width: 154px;
        }
      }
    }
  }

  ul.nav-desktop {
    li {
      > a {
        &:not(.btn){

        }
      }
    }
  }

  #nav-secundary {
    display: flex;
    > li {
      > a:not(.btn) {
        padding: 0 2rem;
        line-height: 64px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  body {
    .nav-container {
      &:not(.sticky) {
        position: absolute;
        background-color: transparent;
      }
    }
  }

  ul.nav-desktop, #nav-secundary {
    > li {
      &.has-subitems {
        position: relative;
        > a {
          &:before {
            width: 100%;
          }
        }
      }
    }
  }

  .nav-container {
    position: fixed;
    top: 0;
    left: 0;

    &.sticky {
      box-shadow: 0 0 15px 4px rgba(0,0,0,0.1);

      .nav-topbar {
        height: 0;
        opacity: 0;
      }
    }
  }


  ul.nav-desktop {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > li {
      padding: 0.5rem 1rem;
      > a {
        &.btn {
          padding: 0.5rem 2rem;
        }
        &:not(.btn){
          padding: 0.125rem 0;
        }
      }
    }
  }

  ul#nav-secundary {
    display: none;
  }
}

@include media-breakpoint-up(xl) {
  body {
    .nav-container {
      .navbar {
        .navbar-brand {
          img {
            max-width: 244px;
          }
        }
      }
    }
  }

  ul.nav-desktop {

    > li {
      padding: 1rem;
      > a {
        &.btn {
          padding: 0.5rem 2rem;
        }
        &:not(.btn){
          padding: 0.125rem 0;
        }
      }
    }
  }
}
