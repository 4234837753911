.breadcrumb {

  li {

    text-transform: uppercase;

    a {
      color: inherit;
    }

    &.last {

    }
  }
}