.section--blue-tr {
  .news-list {
    &:before {
      content: "";
      background-color: rgba(#055c91,.65);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.news-list {
  background-size: cover;
  background-position: center;
  position: relative;
  margin: 0;

  article.news-item {
    display: flex;
    flex-direction: row;

    header {
      flex: 2;
    }

    section {
      padding: 0 0 0 1rem;
      flex: 5;

      h3 {
        @extend %font-family-source-sans-pro;
        @include fluid-type($min_width, $max_width, 25px/1.25, 25px, 1, 1);
        font-weight: 700;
      }

      p {
        margin: 0;
      }
    }
  }
  .news-item__image {
    border-radius: 50%;
    border: 2px solid $white;
  }

  a.news-list-item {
    background-color: rgba($white,0.80);
    position: relative;
    display: block;
    padding: 1rem;
    margin: 1rem 0;
    color: inherit;

    @include transition(0.3s);

    &--highlighted {
      background-color: rgba(#a1b8c8,0.80);
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 30px rgba(0,0,0,.15);
      opacity: 0;
      border-radius: $border-radius;

      @include transition(0.3s);
    }
  }

  a:hover {
    text-decoration: none;
    &:after {
      opacity: 1;
    }
  }

  &--blue {
    a.news-list-item {
      background-color: #f4f6f8;
    }
  }

  &--home {

  }
}

.partner-list {
  .partner-list-item {
    color: inherit;
    .block {
      &__image {
        padding: 0;
        margin: 1rem auto;
        width: 70%;
      }
      &__content {
        padding: 1rem;
        flex-flow: inherit;
        min-height: auto;
        p {
          margin-bottom: 0;
        }
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          box-shadow: 0 0 30px rgba(0,0,0,.15);
          opacity: 0;
          border-radius: $border-radius;

          @include transition(0.3s);
        }
      }
    }
  }
  a:hover {
    text-decoration: none;
    .block__content {
      &:after {
        opacity: 1;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .news-list {
    article.news-item {
      flex-direction: column;
      header {
        width: 20%;
        margin: 0 auto;
      }
      section {
        text-align: center;
        .author {
          justify-content: center;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .news-list {
    &--home {
      a.news-list-item {
        margin: 2rem 0;
      }
    }
    a.news-list-item {
      padding: 1.5rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .news-list {
    article.news-item {
      flex-direction: row;
      section {
        text-align: left;
        .author {
          justify-content: flex-start;
        }
      }
    }
    a.news-list-item {
      padding: 2rem;
    }
    &--home {
      a.news-list-item {
        &:hover {
          background-color: rgba($white,0.9);
        }

        &--first {
          margin-right: -32.5px;
        }

        &--last {
          margin-left: -32.5px;
        }

        &--highlighted {
          margin-top: 4rem;
          margin-left: -17.5px;
          margin-right: -17.5px;
          z-index: 3;
          &:hover {
            background-color: rgba(#a1b8c8,0.9);
          }
        }

      }
    }
  }
  .partner-list {
    .partner-list-item {
      .block__content {
        padding: 1rem;
      }
    }
  }
}

@include media-breakpoint-up(xl) {

  .partner-list {
    .partner-list-item {
      .block__content {
        padding: 1rem 2rem;
      }
    }
  }
}