
%font-family-bree-serif {
  font-family: bree-serif, serif;
}

// semi bold: 600

%font-family-source-sans-pro {
  font-family: source-sans-pro, sans-serif;
}

// light: 300
// regular: 400
// semi bold: 600
// bold: 700

body {
  @extend %font-family-source-sans-pro;
  @include fluid-type($min_width, $max_width, (18px/1.125), 18px, (1.6/1.125), 1.6);
  font-weight: 300;
  font-style: normal;
  color: #353535;
}

h1,.h1 {
  @extend %font-family-bree-serif;
  @include fluid-type($min_width, $max_width, (55px/1.5), 55px, 1, 1);
  font-weight: 600;
  font-style: normal;
}

h2,.h2 {
  @extend %font-family-bree-serif;
  @include fluid-type($min_width, $max_width, (47.5px/1.35), 47.5px, 1, 1);
  font-weight: 600;
  font-style: normal;
}

h3,.h3 {
  @extend %font-family-bree-serif;
  @include fluid-type($min_width, $max_width, (27.5px/1.25), 27.5px, 1, 1);
  font-weight: 600;
  font-style: normal;
}

h4,.h4,h5,.h5,h6,.h6 {
  @extend %font-family-bree-serif;
  @include fluid-type($min_width, $max_width, 18px, 18px, 1, 1);
  font-weight: 600;
  font-style: normal;
}

p,h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
  margin-bottom: 1.5rem;
}


p.text-ellipsis {
  @include limit-lines($min_width, $max_width, (18px/1.125), 18px, (1.6/1.125), 1.6, 3);
}

strong {
  font-weight: 700;
}

.subtitle {
  @include fluid-type($min_width, $max_width, (32.5px/1.125), 32.5px, (1.2/1.125), 1.2);
  margin-bottom: 2.5rem;
}

.lead {
  @extend %font-family-source-sans-pro;
  @include fluid-type($min_width, $max_width, (20px/1.125), 20px, (1.4/1.125), 1.4);
  font-weight: 600;
}

.site-content {
  h3 {
    color: $primary;
  }
}