footer {
  background-size: cover;
  background-position: center;
  position: relative;
  color: $white;

  &:before {
    content: "";
    background-color: rgba(#055c91,.85);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .container {
    padding-bottom: 0!important;
  }

  h4 {
    @extend %font-family-source-sans-pro;
  }

  a {
    color: $white;
    &:hover {
      color: $white;
    }
  }
}