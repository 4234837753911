.image-header-overlay {
  .tabs {
    color: $white;
    .tab-pane-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      .tab-pane {

        &__image {
          border-radius: 50%;
          border: 2px solid $white;
          flex: 1;
        }

        &__content {
          padding: 0 0 0 2rem;
          flex: 5;
        }
      }
    }
  }
}

.tabs {
  display: flex;
  color: black;
  vertical-align: middle;
  align-items: center;
}

.tab-container {
  width: 100%;
}

.tab-component {
  display: none;
}

.nav-tabs  {
  display: flex;
  padding-top: 2rem;
  margin-left: -2rem;
  color: grey;
  border-bottom: none;
  overflow: hidden;
  .nav-link {
    position: relative;
    padding: 0.25rem 2rem;
    @extend %font-family-bree-serif;
    @include fluid-type($min_width, $max_width, (27.5px/1.25), 27.5px, 1, 1);
    font-weight: 600;
    border: none;
    border-right: 2px solid $white;
    border-radius: 0;

    &:before {
      content: "";
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 2rem;
      width: 0;
      background-color: $white;
      transition: 0.3s;
    }

    &:after {
      content: "";
      height: 18px;
      width: 16px;
      position: absolute;
      top: -100px;
      left: calc(50% - 8px);
      border-top: 18px solid $white;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      transition: 0.3s;
    }

    &:hover, &.active{
      background-color: transparent;
      color: $white;

      &:before {
        width: calc(100% - 4rem);
      }

      &:after {
        top: -30px;
      }
    }
    &:not(.active){
      color: $white;
    }
    &:last-child {
      border-right: none;
    }
  }
}

.tab-content {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.accordion {
  .card {
    background-color: transparent;
    color: $body-color;
    border: none;
    &-header {
      background-color: transparent;
      padding: .75rem 1.25rem;
      border-bottom: 1px solid rgba(0,0,0,1);

      @extend %font-family-bree-serif;
      @include fluid-type($min_width, $max_width, (27.5px/1.25), 27.5px, 1, 1);
      font-weight: 600;
      cursor: pointer;
      .custom-arrow {
        float: right;
        &:before {
          border-color: $body-color;
          transform: translate(-10px, 5px) rotate(-45deg);
          transition: all .5s ease;
        }
      }
      &[aria-expanded="true"] {
        color: $black;
        .custom-arrow {
          float: right;

          &:before {
            transform: translate(-10px, 5px) rotate(135deg);
          }
        }
      }
    }
    &-body {
      padding: 1.25rem;
    }
  }
}

@include media-breakpoint-up(md) {
  .accordion {
    display: none;
  }
  .tab-component {
    display: block;
  }
}

@include media-breakpoint-up(lg) {
  .image-header-overlay {
    .tabs {
      .tab-pane-container {
        .tab-pane {
          &__content {
            padding: 0 0 0 3rem;
          }
        }
      }
    }
  }
  .tab-component {
    margin-right: 3rem;
  }
  .nav-tabs {
    margin-left: -3rem;
    .nav-link {
      padding: 0.25rem 3rem;

      &:before {
        left: 3rem;
      }

      &:hover, &.active{
        &:before {
          width: calc(100% - 6rem);
        }
      }
    }
  }
  .tab-content {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}