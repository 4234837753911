$test: 1rem;

body {
  min-width: 320px;
  max-width: 1920px;
  margin: 0 auto;
}

#page-wrapper {
  transition: 0.3s;
  &:before {
    content: "";
    background-color: rgba($primary,0.95);
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
  }
}

.site-content {
  a {
    font-weight: 700;
    text-decoration: underline;
  }
}

.text-center {
  text-align: center;
}

.text-white {
  color: $white;
}

.text-blue {
  color: $primary;
}

[class*="section-shape--top"] {
  position: relative;
  &:before {
    content: "";
    background-position: center;
    width: 100%;
    height: 40px;
    position: absolute;
    top: -2px;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 2;
  }
}

[class*="section-shape--bottom"] {
  position: relative;
  &:after {
    content: "";
    background-position: center;
    width: 100%;
    height: 40px;
    position: absolute;
    bottom: -2px;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 2;
  }
}

.section {
  &--normal {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  &--blue {
    background-color: #f4f6f8;
  }

  &-shape--top {
    &-blue:before {
      background-image: url("../images/shape-top-blue.svg");
    }
    &-white:before {
      background-image: url("../images/shape-top-white.svg");
    }
  }

  &-shape--bottom {
    &-blue:after {
      background-image: url("../images/shape-bottom-blue.svg");
    }
    &-blue-tr:after {
      background-image: url("../images/shape-bottom-blue-tr.svg");
    }
    &-white:after {
      background-image: url("../images/shape-bottom-white.svg");
    }
  }
}

[class*="section-shape--top"] {
  > div {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.related-item {
  background-color: #ffffff;
  padding: 2rem;
}

ul.list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
   margin-bottom: 0.75rem;
  }
}

.btn {
  display: inline-block;
  padding: 0.5rem 2rem;
  //height: 40px;
  @extend %font-family-source-sans-pro;
  @include fluid-type($min_width, $max_width, (18px/1.125), 18px, 1.4, 1.4);
  color: $white;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  border-radius: 20px;
  border: none;
  box-shadow: 0 0 15px 4px rgba(0,0,0,.10);
  cursor: pointer;

  &-primary {
    background-color: $primary;

    &:hover {
      background-color: darken($primary,5%);
      color: $white;
      text-decoration: none;
    }
  }

  &-secondary {
    background-color: $secondary;

    &:hover {
      background-color: darken($secondary,5%);
      color: $white;
      text-decoration: none;
    }
  }
}

.image-header {
  position: relative;

  &:before {
    content: "";
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+30&0.8+1,0+30 */
    background: -moz-linear-gradient(top, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 1%, rgba(255,255,255,0) 30%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255,255,255,0.8) 0%,rgba(255,255,255,0.8) 1%,rgba(255,255,255,0) 30%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255,255,255,0.8) 0%,rgba(255,255,255,0.8) 1%,rgba(255,255,255,0) 30%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ccffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:after {
    content: "";
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+30&0.8+1,0+30 */
    background: -moz-linear-gradient(left, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.5) 1%, rgba(0,0,0,0) 70%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 1%,rgba(0,0,0,0) 70%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to left, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 1%,rgba(0,0,0,0) 70%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ccffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &-home {
    .image-header__background {
      margin-bottom: 2rem;
      &:before {
        content: "";
        background-image: url("../images/shape-bottom-white.svg");
        background-position: center;
        width: 100%;
        height: 40px;
        position: absolute;
        bottom: -2px;
        left: 0;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 2;
      }
    }
  }

  &__background {
    position: relative;
    min-height: 46vw;
  }

  &__tabs {
    position: relative;
  }

  [class*="section-shape--top"] {
    &:before {
      top: -40px;
    }
    > div {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .accordion {
    padding: 1rem 0;
  }
}

ul.nav-topbar {
  padding: 0;
  margin: 0 auto;
  max-width: 1600px;
  height: 68px;
  text-align: right;
  display: none;
  transition: 0.3s;

  li {
    display: inline-block;
    padding: 1.5rem 1rem;
    a {
      position: relative;
      display: inline-block;
      line-height: 20px;
      text-decoration: none;
      color: $body-color;

      &:before {
        content: "";
        height: 1px;
        border-radius: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        background-color: $body-color;
        transition: 0.3s;
      }
    }

    &:hover {
      a {
        &:before {
          width: 100%;
        }
      }
    }

    &.highlighted {
      span {
        margin-right: 1rem;
        font-weight: 700;
      }
      > a {
        color: $secondary;
        text-transform: uppercase;
        font-weight: 700;
        &:before {
          background-color: $secondary;
          width: 100%;
        }
      }
    }

    &.has-subitems {
      position: relative;
      .custom-arrow {
        display: none;
      }
      > a {
        &:before {
          width: 100%;
        }
      }
      .submenu {
        background-color: $white;
        position: absolute;
        top: 100%;
        right: 1rem;
        visibility: hidden;
        opacity: 0;
        border-right: 2px solid $secondary;
        transition: 0.3s;
        z-index: 999;

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          &.submenu-items {
            min-width: 200px;
            @include fluid-type($min_width, $max_width, (20px/1.25), 20px, 1, 1);

            > li {
              display: block;
              position: relative;
              padding: 0.5rem 0;
              margin: 0 1rem;
              white-space: nowrap;

              &:before {
                content: "";
                background-color: $secondary;
                height: 0.75rem;
                width: 0.75rem;
                border-radius: 6px;
                position: absolute;
                top: 0.875rem;
                right: -1.45rem;
                opacity: 0;
                transition: 0.3s;
              }

              a {
                position: relative;
                color: $body-color;
                font-weight: 400;
                text-decoration: none;
                transition: 0.3s;

                &:before {
                  content: "";
                  height: 1px;
                  border-radius: 1px;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 0;
                  background-color: $body-color;
                  transition: 0.3s;
                }
              }

              &.active, &:hover {
                &:before {
                  opacity: 1;
                }
                a {
                  &:before {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }

      //open submenu
      &.show {
        > a:not(.btn) {
          color: $secondary;
          &:before {
            background-color: $secondary;
            width: 100%;
          }
        }

        .submenu {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    &#search {
      vertical-align: middle;
      font-size: 130%;
      a:before {
        display: none;
      }
    }
  }
}

//open submenu on hover
body.is_desktop {
  ul.nav-topbar {
    > li {

      &:hover {
        > a:not(.btn) {
          color: $secondary;
          &:before {
            background-color: $secondary;
            width: 100%;
          }
        }

        .submenu {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

@include media-breakpoint-between(xs,sm) {
  .hidden-for-small {
    display: none;
  }
}

@include media-breakpoint-between(sm,md) {
  .hidden-for-medium {
    display: none;
  }
}

@include media-breakpoint-between(md,lg) {
  .hidden-for-large {
    display: none;
  }
}

@include media-breakpoint-between(lg,xl) {
  .hidden-for-xlarge {
    display: none;
  }
}

@include media-breakpoint-up(md) {
  [class*="section-shape--top"] {
    position: relative;
    &:before {
      height: 80px;
    }
  }

  [class*="section-shape--bottom"] {
    position: relative;
    &:after {
      height: 80px;
    }
  }

  .btn {
    padding: 0.5rem 1rem;
  }
  .section {
    &--normal {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }
  ul.nav-topbar {
    display: block;
  }
  .image-header {
    &-home {
      .image-header__background {
        min-height: 64vw;
        margin-bottom: 0;
        &:before {
          display: none;
        }
      }
    }

    &__background {
      min-height: 26vw;
    }

    &-overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }

    &__text {
      margin-bottom: 80px;
      color: $white;
    }

    &__content {
      font-weight: 500;
    }

    &__tabs {
      background-color: rgba($primary,0.75);
      &:before {
        content: "";
        background-image: url("../images/shape-bottom-blue-tr.svg");
        background-position: center;
        width: 100%;
        height: 80px;
        position: absolute;
        top: -80px;
        left: 0;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 2;
      }
    }

    [class*="section-shape--top"] {
      &:before {
        top: -80px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .btn {
    padding: 0.5rem 2rem;
  }
  .section {
    &--normal {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
  }

  ul.nav-topbar {
    height: 69px;
  }

  .image-header {
    &-home {
      .image-header__background {
        min-height: 64vw;
      }
    }

    &__text {
      margin-bottom: 9vmax;
    }
  }
}

@include media-breakpoint-up(xl) {
  .btn {
    padding: 0.5rem 4rem;
  }
  .image-header {
    &-home {
      .image-header__background {
        min-height: 52vw;
      }
    }
    &__text {
      margin-bottom: 12vmin;
    }
  }
}

.bg-progressive {
  background-color: #ddd;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  overflow: hidden;
  z-index: -2;

  .background-image-small {
    background-size: cover;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 1;
    filter: blur(10px);
  }

  .background-image-large {
    background-size: cover;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s linear;
  }

  .loaded {
    opacity: 1;
  }
}
