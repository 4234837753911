.share {
  position: relative;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      padding: 0;
      margin-right: 14px;

      &:before {
        display: none;
      }

      a {
        color: $white;

        i {
          background-color: $primary;
          display: inline-block;
          margin-right: 4px;

          width: 46px;
          height: 46px;
          line-height: 46px;
          border-radius: 23px;
          font-size: 28px;
          text-align: center;
        }

      }
    }
  }

  &--inverted {
    ul {
      li {
        margin-right: 4px;
        &:before {
          display: none;
        }

        a {
          color: $primary;

          i {
            background-color: $white;
            width: 38px;
            height: 38px;
            line-height: 38px;
            border-radius: 19px;
            font-size: 24px;
          }
        }
      }
    }
  }
}